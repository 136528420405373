var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"ma-0"},[_c('v-row',{staticClass:"ma-0",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"heading-text"},[_vm._v(_vm._s(_vm.heading))])]),_c('v-col',{attrs:{"cols":"2"}},[_c('ScoutingReportScoreChip',{attrs:{"scores":_vm.scores}})],1)],1),(_vm.available)?[(_vm.spiderChartVersion === 1)?_c('v-row',[_c('v-col',{staticClass:"mt-n6",attrs:{"cols":"12"}},[_c('RadarChart',{staticClass:"flex-grow-1",attrs:{"labels":_vm.labels,"data":[{ 'values': _vm.scores,
					'color': 'baColorPrimaryBlue',
					'borderColor': 'baColorVibrantBlue',
					'name': '{{heading}}'
					}]}})],1)],1):_vm._e(),_c('RadarChartJS',{staticClass:"flex-grow-1 my-n6 px-6",attrs:{"labels":_vm.labels,"data":[{
				'values': _vm.scores,
				'color': 'baColorPrimaryBlue',
				'borderColor': 'baColorVibrantBlue',
				'name': '{{heading}}'
			}]}})]:_c('v-card',{staticClass:"mt-6 d-flex align-center justify-center",attrs:{"flat":""}},[_vm._v(" Chart not available ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }