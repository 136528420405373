
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class GameWhistleSvg extends Mixins(VuetifyMixin){
	@Prop({ default: '#797979' }) private color: string;
	@Prop({ default: 20 }) private size: string;
	get Color(): string{
		return this.getColor(this.color);
	}
}
