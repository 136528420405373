import { Component, Vue } from 'vue-property-decorator';
import { ScoutingReportScore, ScoutingReportScoreValues } from "@/../types/enums";
import { SelectorItem } from './selectorItem';

@Component
export class ScoutingReportScoreSelectorMixin extends Vue {
	get AvailableScoutingReportScoreValues(): SelectorItem<ScoutingReportScore>[] {
		return ScoutingReportScoreValues.map((value, i) => ({
			text: i === 4 ? '4+' :`${value}`,
			value: value,
		}));
	}
}