
import { Component, Prop, Mixins } from 'vue-property-decorator'
import CalendarProvider from '@/components/hoc/CalendarProvider.vue';
import Calendar from '../components/calendar/Calendar.vue';
import CalendarV2 from '../components/calendar/CalendarV2.vue';
import { CalendarMode, CalendarModeValues } from '../components/calendar/types/CalendarMode';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { mdiClose, mdiMagnify, mdiChevronLeft } from '@mdi/js';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { title } from '../pipes/title.pipe';
import { VCalendarDay } from '@/components/calendar/types/VCalendarDay';
import { VueConstructor } from 'vue';
import { FeatureFlagMixin } from '@/mixins';

@Component({
	components: {
		CalendarProvider,
		CalendarV2,
		Calendar
	},
})
export default class FullScreenCalendar extends Mixins(FeatureFlagMixin){
	@Prop() teamId: string;
	@Prop({ default: false, type: Boolean }) athleteMode: boolean;
	types = CalendarModeValues;
	mdiClose = mdiClose;
	mdiMagnify = mdiMagnify;
	title = title;
	mdiChevronLeft = mdiChevronLeft;

	get AthleteMode(): boolean{
		return this.athleteMode;
	}

	get IsMobile(): boolean{
		return this.$vuetify.breakpoint.xs;
	}

	calendarMode: CalendarMode = CalendarMode.Month;
	updateCalendarMode(mode: CalendarMode): void{
		this.calendarMode = mode;
	}

	addEvent({ focusDate }: { focusDate?: string }): void{
		if(this.AthleteMode) return;
		this.$router.push({
			name: Routes.TeamEventCreate,
			params:{
				...this.$route.params,
			},
			query:{
				focusDate,
				largeCalendar: 'true',
			}
		});
	}

	eventClicked({calendarEvent, $event}: {calendarEvent: CalendarEventModel, $event: VCalendarDay<CalendarEventModel>}): void{
		if(this.AthleteMode){
			this.$router.push({
				name: Routes.AthleteTeamEventView,
				params:{
					teamId: this.teamId,
					teamEventId: calendarEvent.id,
				},
				query: {
					focusDate: new Date($event.day.date.replace('-', ' ')).toISOString(),
					largeCalendar: 'true',
				}
			});
		}else{
			this.$router.push({
				name: Routes.TeamEventEdit,
				params:{
					teamId: this.teamId,
					teamEventId: calendarEvent.id,
				},
				query: {
					focusDate: new Date($event.day.date.replace('-', ' ')).toISOString(),
					largeCalendar: 'true',
				}
			});
		}
	}

	backToTeamDashboard(): void{
		if (this.AthleteMode){
			this.$router.push({
				name: Routes.AthleteTeamDashboard,
				params: { teamId: this.teamId },
			})
		} else {
			this.$router.push({
				name: 'TeamDashboard',
				params: { teamId: this.teamId },
			})
		}
	}

	get CalendarComponent(): VueConstructor {
		if(this.feature('HIN-1602-large-calendar-view-v2')){
			return CalendarV2;
		}
		return Calendar;
	}
}
