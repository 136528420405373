
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiCommentProcessingOutline } from '@mdi/js';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';

@Component
export default class ScoutingReportNotesBtn extends Mixins(VuetifyMixin){
    @Prop({ type: Boolean, default: false }) badgeVisible: boolean;
    @Prop({ type: Boolean, default: false }) disabled: boolean;
    mdiCommentProcessingOutline = mdiCommentProcessingOutline
}
