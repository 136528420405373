
import { Component, Prop, Mixins } from "vue-property-decorator";
import { CurrentTeamMixin, AuthMixin } from '@/mixins';
import CalendarProvider from '@/components/hoc/CalendarProvider.vue';
import TeamEvent from '@/components/teams/events/TeamEvent.vue';
import { CalendarModel } from '@/models/calendar/CalendarModel';
@Component({
	components: {
		CalendarProvider,
		TeamEvent
	},
})

export default class TeamEventView extends Mixins(CurrentTeamMixin, AuthMixin) {
	@Prop() teamId: string;
	@Prop() teamEventId: string;
	@Prop({ type: Boolean, default: false }) athleteMode;
	@Prop({ type: Date, default: null }) focusDate: string;
	
	calendar: CalendarModel = null;
	mounted(): void {
		if (this.$route.query.focusDate !== undefined && this.$route.query.focusDate !== null){
			this.focusDate  = this.$route.query.focusDate as string;
		}
	}
	get CalendarId(): string | undefined{
		if(this.calendar === null) return undefined;
		return this.calendar.id;
	}

	get AthleteMode(): boolean{
		return this.athleteMode;
	}
}
