
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component, Prop, Mixins } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { CalendarEventParticipant } from "../../../types/interfaces";
import { TeamModel } from "@/models/team";
import { UserProfileModel } from "@/models/UserProfileModel";
import {
	mdiCheck,
	mdiClose,
	mdiProgressQuestion,
	mdiPencil,
} from '@mdi/js'
import { Tardiness } from '../../../types/enums/tardiness';

@Component
export default class TeamAttendance extends Mixins(VuetifyMixin) {

	mdiCheck = mdiCheck;
	mdiClose = mdiClose;
	mdiProgressQuestion = mdiProgressQuestion;
	mdiPencil = mdiPencil;

	@Prop({ default: [] }) participants: CalendarEventParticipant[];
	@Prop({ default: false }) loading: boolean;
	@Prop({ required: true }) team: TeamModel;
	@Prop({default: 650}) height: number;
	@Prop({ default: false }) readOnly: boolean;
	@Prop({default: true}) editAttendance: boolean;
	showFinishAttendance: boolean = false;
	attendanceStatus: Tardiness | null;

	isActive(player: CalendarEventParticipant, status: string): boolean{
		if (status === 'on-time'){
			this.attendanceStatus = Tardiness.OnTime
		} else if (status === 'late'){
			this.attendanceStatus = Tardiness.Late
		} else if (status === 'absent'){
			this.attendanceStatus = Tardiness.Absent
		}
		if (this.attendanceStatus === player.tardiness) {
			return true
		}
		return false
	}

	cancelShowAttendance():void{
		this.showFinishAttendance = false;
	}
	confirmShowAttendance():void{
		this.showFinishAttendance = false;
		this.$emit('update:attendanceProgress', false)
	}

	updatedParticipants(): void{
		this.$emit('update:participants',this.participants)
	}

	attendanceFinish(isFinished: boolean): void{
		if (!isFinished){
			this.showFinishAttendance = true;
		}
		this.$emit('update:attendanceProgress', true)
	}


	get Players(): CalendarEventParticipant[] {
		return this.participants;
	}
	get ScrollableHeight(): {'overflow-y':string, 'max-height':string}{
		return {'overflow-y':'auto', 'max-height': `${this.height - 140}px`};
	}

	get NeutralButton():Record<string, string>{
		return {
			'background-color': 'white !important',
		}
	}

	get OnTimeButton():Record<string, string>{
		return {
			color: 'white',
			'background-color': 'green',
		}
	}

	get LateButton(): Record<string,string> {
		return{
			color: 'white',
			'background-color': '#CE6100',
		}
	}

	get AbsentButton():Record<string,string>{
		return{
			color: 'white',
			'background-color': '#C80404',
		}
	}
	get FormColumns(): {
		RSVP: Record<string, string>;
		Name: Record<string, string>;
		Status: Record<string, string>;
	} {
		return {
			RSVP: {
				cols: "12",
				md: "2",
				sm: "2",
			},
			Name: {
				cols: "12",
				md: "4",
				sm: "4",
			},
			Status:{
				cols: "12",
				md: "5",
				sm: "5",
			}

		};
		
	}

	playerToUserInfo(player: CalendarEventParticipant): Partial<UserProfileModel> {
		const playerToUser = this.team.players.find(p => p.id === player.id)
		if (playerToUser){
			return {
				firstName: playerToUser.firstName,
				lastName: playerToUser.lastName
			};
		}
	}
}
