
import { Component, Mixins, Prop } from "vue-property-decorator";
import ScoutingReportDetailProvider, { SaveReportFn } from "@/components/hoc/ScoutingReportDetailProvider.vue";
import { formatDate, formatDatePretty } from "@/helpers/date";
import { MyCoachMixin,VuetifyMixin, FeatureFlagMixin } from "@/mixins";
import ScoreSelector from '@/components/forms/ScoreSelector.vue';
import RadarChartJS from "@/components/charts/RadarChartJS.vue";
import { ScoutingReportScoreSelectorMixin } from "@/mixins/selectors/ScoutingReportScoreSelector";
import { title } from '../pipes';
import { mdiChevronLeft, mdiEmail, mdiAccountClock, mdiAccount } from '@mdi/js';
import CircledAvatar from '../components/ui/CircledAvatar.vue';
import { CoachScoutingSummary } from '../../types/constants/web_client_user.routes';
import { ConfirmationDialog } from "@/components/ui/ConfirmationDialog.vue";
import ScoutingReportSpiderChart from "@/components/scoutingReport/ScoutingReportSpiderChart.vue";
import ScoutingReportMatrix from "@/components/scoutingReport/ScoutingReportMatrix.vue";
import ScoutingReportMatrixV2 from "@/components/scoutingReport/ScoutingReportMatrixV2.vue";
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { Route } from 'vue-router';
import { notificationStore } from '@/store';
import ScoutingReportNotesBtn from "@/components/scoutingReport/ScoutingReportNotesBtn.vue";
import { ScoutingReportEvaluationMatrix } from '@best-athletes/ba-types';
import ProfileProvider from '@/components/hoc/ProfileProvider.vue';

@Component({
	components: {
		ScoutingReportDetailProvider,
		ScoreSelector,
		RadarChartJS,
		CircledAvatar,
		ConfirmationDialog,
		ScoutingReportMatrix,
		ScoutingReportMatrixV2,
		ScoutingReportSpiderChart,
		ScoutingReportNotesBtn
	},
})

export default class ViewCoachScoutingReport extends Mixins(MyCoachMixin, VuetifyMixin, ScoutingReportScoreSelectorMixin, FeatureFlagMixin){
	formatDate = formatDate;
	formatDatePretty = formatDatePretty;
	back_icon = mdiChevronLeft;
	email_icon = mdiEmail;
	pending_icon = mdiAccountClock;
	noaccount_icon = mdiAccount;
	title = title;
	@Prop({ default: null, type: String }) reportId: string | null;
	@Prop({ default: null, type: String }) athleteId: string | null;
	submitDialogVisible: boolean = false;
	loading: boolean = false;
	email: string = "";
	errorMessage: string = "";
	get CoachName(): string{
		return `${this.MyCoachProfile.firstName} ${this.MyCoachProfile.lastName}`
	}
	get EvaluationCols(): {left: Record<string, any>, right: Record<string, any>}{
		return {
			left: {
				cols:"12", md:"3", lg:"4",
			},
			right: {
				cols:"12", md:"9", lg:"8",
			},
		}
	}

	async initialSubmit(validate: () => Promise<boolean>): Promise<void> {
		const isValid = await validate();
		if (!isValid) {
			this.errorMessage = 'Please ensure all required fields are provided.';
			this.$vuetify.goTo(0);
		}
		else {
			this.submitDialogVisible = true;
		}
	}

	cancelSubmit(): void{
		this.submitDialogVisible = false;
		this.email = "";
	}
	
	goToScoutingSummary(): void{
		this.$router.push({
			name: CoachScoutingSummary,
			params:{
				...this.$route.params,
			}
		});
	}
	
	async sendEmail(saveReport: SaveReportFn, report: ScoutingReportModel, email: string, validate: () => Promise<boolean>): Promise<void>{
		const isValid = await validate();
		if (isValid){
			this.loading = true;
			let newReport = report;
			newReport.contactInfo.email = email;
			try{
				await saveReport(newReport, true);
				this.goToScoutingSummary();
			}catch(e){
				console.error("Failed to save scouting report", e);
				notificationStore.pushLocalNotificationMessage("Failed to save scouting report. Please try again later");
			}finally{
				this.loading = false;
			}
		}
	}
	goToScoutingReports(): void{
		this.$router.push({ name: CoachScoutingSummary });
	}

	viewAthleteRoute(athleteId: string): Partial<Route>{
		return {
			name: "CoachViewAthlete",
			params:{
				athleteId
			}
		}
	}
}

