
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component, Prop, Mixins } from "vue-property-decorator";
import { VuetifyMixin } from "../../mixins/VuetifyMixin";
import { mdiPencil } from "@mdi/js";
// @ts-ignore
import yellowCard from "../../assets/img/yellow-card.png";
// @ts-ignore
import redCard from "../../assets/img/red-card.png";
// @ts-ignore
import substitution from "../../assets/img/substitution-player.png";
// @ts-ignore
import goal from "../../assets/img/soccer-ball.png";
import { SoccerGameOccurenceType, SoccerTimelinePeriod, SoccerGameTiming } from '../../../types/enums';
import { GameOccurence } from '../../../types/interfaces';

@Component({
	components: { },
})
export default class SoccerGameTimeline extends Mixins(VuetifyMixin) {
	goal = goal;
	substitution = substitution;
	mdiPencil = mdiPencil;
	@Prop() ourTeamName: string;
	@Prop() opponentName: string;
	@Prop() events: GameOccurence[];
	@Prop({ default: false }) isAthlete: boolean;
	@Prop({ default: null }) gameTiming: SoccerGameTiming;

	get IsOvertime(): boolean{
		return this.gameTiming === 'Overtime';
	}
	get OurTeamName(): string {
		return this.ourTeamName;
	}
	get OpponentName():string {
		return this.opponentName;
	}

	get TeamParse():{ firstHalf: GameOccurence[], secondHalf: GameOccurence[] }{
		return {
			firstHalf: this.events.filter(e => e.period === SoccerTimelinePeriod.FirstHalf).sort(this.sortByTime).slice(0,12),
			secondHalf: this.events.filter(e => e.period === SoccerTimelinePeriod.SecondHalf).sort(this.sortByTime).slice(0,12)
		}
	}

	sortByTime = (a: GameOccurence, b: GameOccurence): number => a.eventTime - b.eventTime;

	isOpposingTeam(occurence: GameOccurence): boolean{
		return occurence.team === this.OpponentName;
	}

	getOccurencePic(occurence: GameOccurence): string{
		switch(occurence.type){
		case SoccerGameOccurenceType.Goal:
			return goal;
		case SoccerGameOccurenceType.Substitution:
			return substitution;
		case SoccerGameOccurenceType.YellowCard:
			return yellowCard;
		case SoccerGameOccurenceType.RedCard:
			return redCard;
		default:
			return "";
		}
	}

	get HalfTimeStyle():{color:string}{
		return{
			color: this.getColor('baColorDeepBlueText'),
		}
	}

	get TimelineStyle():{'background-color':string,'height':string}{
		return{
			'background-color':this.getColor('baColorDeepBlueText'),
			'height':'22px',
		}
	}
}
