
import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import { mdiGoogle, mdiMicrosoftWindows, mdiCheck } from "@mdi/js";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { CalendarUrls } from '../hoc/CalendarProvider.vue';

const DefaultCalendarURLs: CalendarUrls = {
	ics: '#',
	webcal: '#',
	google: '#',
	outlook: '#',
}

@Component
export default class CalendarExportDialog extends Mixins(VuetifyMixin) {
	@PropSync('show', { default: false, type: Boolean }) Show: boolean;
	@Prop({ default: DefaultCalendarURLs }) calendarURLs: CalendarUrls;

	mdiGoogle = mdiGoogle;
	mdiMicrosoftWindows = mdiMicrosoftWindows;
	mdiCheck = mdiCheck;

	copyClicked: boolean = false;
}
