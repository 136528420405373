
import { Component, Mixins } from 'vue-property-decorator';
import TeamDashboard from './TeamDashboard.vue';
import TeamDashboardV2 from './TeamDashboardV2.vue';
import { VueConstructor } from 'vue';
import { FeatureFlagMixin } from '@/mixins';

@Component({
	components: {
		TeamDashboard,
		TeamDashboardV2,
	}
})
/**
 * Root view for the Athlete or Coach's TeamDashboard. Chooses which component to be rendered
 */
export default class TeamDashboardView extends Mixins(FeatureFlagMixin){
	get TeamDashboardComponent(): VueConstructor{
		if(this.feature('HIN-1600-team-dashboard-layout-v2')){
			return TeamDashboardV2;
		}
		return TeamDashboard;
	}
}
