
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { MyCoachMixin, VuetifyMixin, FeatureFlagMixin } from "@/mixins";
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { ScoutingReportScoreSelectorMixin } from "@/mixins/selectors/ScoutingReportScoreSelector";
import ScoreSelector from '@/components/forms/ScoreSelector.vue';
import ScoutingReportNotesBtn from "@/components/scoutingReport/ScoutingReportNotesBtn.vue";
import { ScoutingReportEvaluationMatrix } from '@best-athletes/ba-types';
import { ConfirmationDialog } from "@/components/ui/ConfirmationDialog.vue";

@Component({
	components: {
		ScoreSelector,
		ScoutingReportNotesBtn,
	},
})

export default class ScoutingReportMatrix extends Mixins(MyCoachMixin, VuetifyMixin, ScoutingReportScoreSelectorMixin){
	@Prop({ default: null, type: String }) report: ScoutingReportModel | null;

	get EvaluationCols(): {left: Record<string, any>, right: Record<string, any>}{
		return {
			left: {
				cols:"12", md:"3", lg:"4",
			},
			right: {
				cols:"12", md:"9", lg:"8",
			},
		}
	}

	notesDialogVisible: boolean = false;
	notesFor: (keyof ScoutingReportEvaluationMatrix) | null = null;
	openNotesDialog(notesFor: (keyof ScoutingReportEvaluationMatrix)): void {
		this.notesDialogVisible = true;
		this.notesFor = notesFor;
	}
}
