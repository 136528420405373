
import { Component, Vue, Prop } from 'vue-property-decorator';
import ScoutingReportScoreChip from "@/components/scoutingReport/ScoutingReportScoreChip.vue";
import RadarChart from "@/components/charts/RadarChart.vue";
import RadarChartJS from "@/components/charts/RadarChartJS.vue";

@Component({
	components:{ ScoutingReportScoreChip, 
				 RadarChart,
				 RadarChartJS
			   },
})
export default class ScoutingReportSpiderChart extends Vue{
	@Prop() public available: boolean;
	@Prop({ required: true }) public heading: string;
	@Prop({ required: true }) public labels: string[];
	@Prop({ required: true }) public scores: number[];

	spiderChartVersion = 2
}
