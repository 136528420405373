
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { athleteApi } from '@/api/AthleteApi';
import { AppHostnameMixin, MyCoachMixin } from '@/mixins';
import convert from 'convert-units';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel';
import { errorIs403, errorIs401 } from '@/helpers/error-handler';
import CoachRecruitingStore from '@/store/targetedSharing/CoachRecruiting.store';
import { getModule } from 'vuex-module-decorators';
const coachRecruitingSharingStore = getModule(CoachRecruitingStore);

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	},
})
export default class RecruitingProfileInfoProvider extends Mixins(AppHostnameMixin, MyCoachMixin){
	@Prop({ default: 'div' }) as: string;
	/** The AthleteId in question */
	@Prop({ type: String, default: null }) athleteId: string | null;
	/** URL ID from the general share URL */
	@Prop({ type: String, default: null }) sharingUrlId: string | null;
	ready = false;
	error = false;
	athleteProfile = null;
	athleteTeams = null;
	athleteAssessmentData = null;
	selfAssessmentSummary = null;
	athleteSpqData = null;

	async created(): Promise<void> {
		await this.initProfile();
	}

	async loadRecruitingProfile(): Promise<RecruitingProfileModel> {
		this.error = false;
		try{
			if (this.sharingUrlId !== null) {
				return await athleteApi.getSharingProfile(this.athleteId, this.sharingUrlId)
			}
			else {
				return await athleteApi.getAthleteRecruitingProfile(this.athleteId, {as : this.getAppType} );
			}
		}catch(e){
			console.log("Error Loading Recruiting Profile", e);
			if(errorIs401(e) || errorIs403(e)){
				const profile = await coachRecruitingSharingStore.getRecruitingProfileIfShareExists({
					athleteId: this.athleteId,
					coachId: this.MyCoachId
				});
				if(profile !== null) return profile;
			}
			throw e;
		}
	}
	async initProfile():Promise<void> {
		try{
			this.ready = false;
			const recruitingProfile = await this.loadRecruitingProfile();
			// Set the athlete profile and teams
			this.athleteProfile = recruitingProfile.athleteProfile;
			this.athleteTeams = recruitingProfile.teams;
			this.athleteAssessmentData = recruitingProfile.physicalPerformance;
			this.selfAssessmentSummary = recruitingProfile.selfAssessment;
			this.athleteSpqData = recruitingProfile.SPQResults;
	
			// Restore the height/weight to user preference
			this.athleteProfile.height = parseFloat(
				convert(this.athleteProfile.height)
					.from("cm")
					.to(this.athleteProfile.heightUnit)
					?.toFixed(2)
			);
			this.athleteProfile.weight = parseFloat(
				convert(this.athleteProfile.weight)
					.from("kg")
					.to(this.athleteProfile.weightUnit)
					?.toFixed(2)
			);
		}catch(error){
			console.error("Failed to load athlete profile", error);
			this.error = true;
		}
		this.ready = true;
		
	}
	get SlotProps() {
		return {
			athleteProfile: this.athleteProfile,
			athleteTeams: this.athleteTeams,
			athleteAssessmentData: this.athleteAssessmentData,
			selfAssessmentSummary: this.selfAssessmentSummary,
			athleteSpqData: this.athleteSpqData,
			Loading: this.Loading,
			Ready: this.Ready,
			Error: this.error,
		}
	}
	get Loading(): boolean {
		return !this.ready;
	}
	get Ready(): boolean {
		return this.ready;
	}
}
