var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Page',{attrs:{"fluid":false,"loading":_vm.PageLoading,"no-padding":_vm.IsMobile}},[_c('TeamDashboardInfoProvider',{attrs:{"teamId":_vm.teamId,"load-team":"","load-team-events":""},scopedSlots:_vm._u([{key:"default",fn:function({
			CurrentTeamReady,
			CurrentTeam,
			CurrentTeamId,
			teamEvents,
			PageState
		}){return [(PageState.IsError)?_c('FailureStateCard',{attrs:{"state":PageState}}):[(_vm.IsMobile)?_c('TeamDashboardMobileNav',{attrs:{"nav-items":_vm.DashboardNavItems,"team":CurrentTeam,"loading":PageState.IsLoading}}):_vm._e(),(_vm.ShowTeamDetailsCard)?_c('v-row',[_c('v-col',[_c('TeamDetailsCardV2',{attrs:{"flat":"","team":CurrentTeam,"readOnly":_vm.AthleteMode,"loading":_vm.PageLoading}})],1)],1):_vm._e(),_c('div',{staticClass:"d-flex"},[(!_vm.IsMobile)?_c('v-navigation-drawer',{staticClass:"mr-4 transparent",attrs:{"floating":"","permanent":"","width":"175"}},[_c('v-list',{staticClass:"pt-0"},_vm._l((_vm.DashboardNavItems),function(navItem){return _c('v-list-item',{key:navItem.text,class:_vm.DashboardNavItemClasses,attrs:{"active-class":"nav-active","to":navItem.route,"dense":""}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c(navItem.iconComponent,{tag:"component",attrs:{"size":"24"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"baColorSecondaryText--text"},[_vm._v(" "+_vm._s(navItem.text)+" ")])],1)],1)}),1)],1):_vm._e(),_c('div',{staticClass:"flex-grow-1"},[_c('router-view',_vm._g(_vm._b({attrs:{"teamEvents":teamEvents}},'router-view',_vm.RouterViewProps.props,false),_vm.RouterViewProps.on))],1)],1)]]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }