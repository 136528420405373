

import {
	Chart as ChartJS,
	Legend,
	LineElement,
	RadialLinearScale,
	PointElement,
	Title,
	Tooltip,
	ChartData,
	ChartOptions,
registerables,
} from 'chart.js';
import { Radar } from 'vue-chartjs/legacy';
ChartJS.register(
	Title,
	Tooltip,
	Legend,
	PointElement,
	RadialLinearScale,
	LineElement
)

interface Point {
	x: number;
	y: number
}

import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
import { RadarChartSeries } from "@/../types/interfaces/RadarChartSeries";

@Component({
	components:{
		Radar,
	}
})
export default class RadarChartJS extends Mixins(VuetifyMixin){

	@Prop({default: 0, type: Number }) private scaleMin: number;
	@Prop({default: 6, type: Number }) private scaleMax: number;
	@Prop({default: 9, type: Number }) private labelSize: number;
	@Prop({required: true}) public labels: string[];
	@Prop({required: true}) public data: RadarChartSeries[];

	get RadarChartData(): ChartData{
		return {
			// display labels in uppercase
			labels: this.labels.map(l => {return l.toUpperCase()}),

			// chart data
			datasets: this.data.map(data => {
				return {
					label: data.name,
					data: data.values,
					borderColor: this.getColor(data.borderColor),
					backgroundColor: this.getColor(data.color),
				}
			})
		}
	}

	get RadarChartDataOptions(): ChartOptions {
		return {
			responsive: true,
			maintainAspectRatio: true,
			elements: {
				point: {
					radius: 8,
					hitRadius: 24,
					hoverRadius: 32,
					hoverBorderWidth: 8,
				},
			},
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					backgroundColor: this.getColor('baColorDeepBlue'),
					titleColor: 'white',
					displayColors: false,
					titleAlign: 'center',
					titleFont: {
						size: 16,
						weight: 'bold'
					},
					bodyFont: {
						size: 32					
					},
					bodyAlign: 'center',
					callbacks: {
						title: function(context) {
							return context[0].label.toUpperCase();
						},
						label: function(context) {
							return context.formattedValue;
						},
					}
				}
			},
			scales: {
				r: {
					pointLabels: {
						color: this.getColor('baColorDeepBlue'),
						font: {
							size: this.labelSize,
							weight: 'bold',
						}
					},
					grid: {
						display: true,
						circular: true
					},
					ticks: {
						display: false,
						stepSize: 1
					},
					min: this.scaleMin,
					max: this.scaleMax,
				}
			}
		}
	}
}

