
import Vue from 'vue'
export default Vue.extend({
	props:{
		value:{
			type: Number,
			required: true,
		},
		label:{
			type: String,
			default: "Shares",
		},
	}
})
