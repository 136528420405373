
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { notificationStore } from '@/store';
import { scoutingReportApi } from '@/api/ScoutingReportApi';
import { PageState } from '@/models/PageState';

export type SaveReportFn = (report: ScoutingReportModel, sendReport: boolean) => Promise<ScoutingReportModel>

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	},
})
export default class ScoutingRecordDetailProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	@Prop({ default: null }) reportId: string | null;
	/** Provide to load a report linked to an athlete Id. Requires Read access to athlete */
	@Prop({ default: null }) athleteId: string | null;
	
	async created(): Promise<void> {
		await this.initReport();
	}
	async loadScoutingData(): Promise<void> {
		if(this.reportId === null) return;
		if(this.athleteId === null){
			this.scoutingReport = await scoutingReportApi.findById(this.reportId);
		}else{
			this.scoutingReport = await scoutingReportApi.findByAthleteId(this.athleteId, this.reportId);
		}
		if(this.scoutingReport === null){
			throw null; // Render 404 Not Found Error
		}
	}
	state: PageState = new PageState("Initial");

	scoutingReport: ScoutingReportModel | null = null;

	async initReport():Promise<void> {
		this.state = new PageState("Loading");
		try{
			await this.loadScoutingData();
			this.state = new PageState("Ready");
		}catch(error){
			if(error !== null){
				notificationStore.pushErrorNotificationMessage("Failed to load Scouting Report. Please try again later.");
			}
			this.state = PageState.getPageState(error);
		}
	}

	get SlotProps(): Record<string,any> {
		return {
			state: this.state,
			ScoutingReport: this.scoutingReport,
			saveReport: this.saveReport.bind(this),
			FourCornerAvailable: this.FourCornerAvailable,
			SixMomentAvailable: this.SixMomentAvailable,
			FourMomentAvailable: this.FourMomentAvailable,
			CRISPAvailable: this.CRISPAvailable,
			FourCornersLabels: this.FourCornersLabels,
			FourCornersValues: this.FourCornersValues,
			SixMomentEvaluationLabels: this.SixMomentEvaluationLabels,
			SixMomentValues: this.SixMomentValues,
			CRISPEvaluationLabels: this.CRISPEvaluationLabels,
			CRISPValues: this.CRISPValues,
			OverallChartAvailable: this.OverallChartAvailable,
			OverallChartLabels: this.OverallChartLabels,
			OverallChartValues: this.OverallChartValues,
		}
	}

	get FourCornerAvailable(): boolean{
		if (!this.scoutingReport) return false;
		if (this.scoutingReport.evaluationMatrix.technical && this.scoutingReport.evaluationMatrix.tactical && this.scoutingReport.evaluationMatrix.physical && this.scoutingReport.evaluationMatrix.mental){
			return true
		}
		return false
	}

	get FourMomentAvailable(): boolean {
		if (!this.scoutingReport) return false;
		if (this.scoutingReport.evaluationMatrix.attackingOrganization && this.scoutingReport.evaluationMatrix.attackingTransition && this.scoutingReport.evaluationMatrix.defendingOrganization && this.scoutingReport.evaluationMatrix.defendingTransition){
			return true
		}
		return false
	}

	get SixMomentAvailable(): boolean {
		if (!this.scoutingReport) return false;
		if (this.FourMomentAvailable && this.scoutingReport.evaluationMatrix.attackingSetPlays && this.scoutingReport.evaluationMatrix.defendingSetPlays){
			return true
		}
		return false
	}

	get CRISPAvailable(): boolean{
		if (!this.scoutingReport) return false
		if (this.scoutingReport.evaluationMatrix.competitive && this.scoutingReport.evaluationMatrix.resilience && this.scoutingReport.evaluationMatrix.intelligence && this.scoutingReport.evaluationMatrix.speed && this.scoutingReport.evaluationMatrix.presence){
			return true
		}
		return false
	}

	get FourCornersLabels(): string[]{
		return ['Technical', 'Tactical', 'Physical', 'Mental'];
	}
	get FourCornersValues(): number[]{
		if (!this.FourCornerAvailable) return [];
		return [
			this.scoutingReport.evaluationMatrix.technical,
			this.scoutingReport.evaluationMatrix.tactical,
			this.scoutingReport.evaluationMatrix.physical,
			this.scoutingReport.evaluationMatrix.mental,
		]
	}

	get FourMomentsValues(): number[]{
		if (!this.FourMomentAvailable) return [];
		return [
			this.scoutingReport.evaluationMatrix.attackingOrganization,
			this.scoutingReport.evaluationMatrix.attackingTransition,
			this.scoutingReport.evaluationMatrix.defendingOrganization,
			this.scoutingReport.evaluationMatrix.defendingTransition,
		]
	}
	get SixMomentEvaluationLabels(): string[]{
		return [
			'Att Org', 'Att Trans', 'Att Set Plays',
			'Def Org', 'Def Trans', 'Def Set Plays'
		];
	}
	get SixMomentValues(): number[]{
		if (!this.SixMomentAvailable) return [];
		return  [
			this.scoutingReport.evaluationMatrix.attackingOrganization,
			this.scoutingReport.evaluationMatrix.attackingTransition,
			this.scoutingReport.evaluationMatrix.attackingSetPlays,
			this.scoutingReport.evaluationMatrix.defendingOrganization,
			this.scoutingReport.evaluationMatrix.defendingTransition,
			this.scoutingReport.evaluationMatrix.defendingSetPlays,
		]
	}
	get CRISPEvaluationLabels(): string[]{
		return ['Competitive', 'Resilience', 'Intelligence', 'Speed', 'Presence'];
	}
	get CRISPValues(): number[]{
		if (!this.CRISPAvailable) return [];
		return [
			this.scoutingReport.evaluationMatrix.competitive,
			this.scoutingReport.evaluationMatrix.resilience,
			this.scoutingReport.evaluationMatrix.intelligence,
			this.scoutingReport.evaluationMatrix.speed,
			this.scoutingReport.evaluationMatrix.presence
		]
	}
	get OverallChartAvailable(): boolean{
		return this.FourCornerAvailable && this.SixMomentAvailable && this.CRISPAvailable;
	}
	get OverallChartLabels(): string[]{
		return [
			...this.FourCornersLabels,
			...this.SixMomentEvaluationLabels,
			...this.CRISPEvaluationLabels,
		]
	}
	get OverallChartValues(): number[]{
		return [
			...this.FourCornersValues,
			...this.SixMomentValues,
			...this.CRISPValues,
		]
	}

	async saveReport(report: ScoutingReportModel, sendReport: boolean = false): Promise<ScoutingReportModel> {
		let saved: ScoutingReportModel;
		if(!report.id){
			saved = await scoutingReportApi.insertWithOwnership(report, { params: { supressEvents: false, sendReport }});
		}else{
			saved = await scoutingReportApi.save(report, { params: { supressEvents: false, sendReport }});
		}
		/** Update our loaded report if they're the same */
		if(this.scoutingReport && saved.id === this.scoutingReport.id){
			this.scoutingReport = new ScoutingReportModel().load({
				...this.scoutingReport,
				playerDetails:{
					...saved.playerDetails,
				},
				contactInfo:{
					...saved.contactInfo,
				},
				lastModified: saved.lastModified,
				anonymousCoach: saved.anonymousCoach
			})
		}
		return saved;
	}

}
