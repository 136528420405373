
import { List } from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ScoutingReportScoreChip extends Vue{
	// ScoutingReportScoreChip class properties
	@Prop({ required: true }) public scores: number[];

	// Compute the score based on the average of the scores passed in
	get compute_score(): Number {
		const sum = this.scores.reduce((a,b) => a+b, 0);
		return (sum / this.scores.length) || 0;
	}
}
