
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { MyCoachMixin, VuetifyMixin, FeatureFlagMixin } from "@/mixins";
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { ScoutingReportScoreSelectorMixin } from "@/mixins/selectors/ScoutingReportScoreSelector";
import ScoreSelector from '@/components/forms/ScoreSelector.vue';
import ScoutingReportNotesBtn from "@/components/scoutingReport/ScoutingReportNotesBtn.vue";
import ScoutingMatrixElement from "@/components/scoutingReport/ScoutingMatrixElement.vue";
import { ScoutingReportEvaluationMatrix } from '@best-athletes/ba-types';
import { ConfirmationDialog } from "@/components/ui/ConfirmationDialog.vue";
import { mdiCommentEye, mdiCommentOffOutline } from '@mdi/js';

@Component({
	components: {
		ScoreSelector,
		ScoutingReportNotesBtn,
		ScoutingMatrixElement,
	},
})

export default class ScoutingReportMatrixV2 extends Mixins(MyCoachMixin, VuetifyMixin, ScoutingReportScoreSelectorMixin){
	@Prop({ default: null, type: String }) report: ScoutingReportModel | null;

	iconShowComments = mdiCommentEye;
	iconHideComments = mdiCommentOffOutline;

	showComments: boolean = true;
}
