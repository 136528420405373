
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
import { SelectorItem } from '@/mixins/selectors/selectorItem';
import { mdiCheck } from '@mdi/js';

@Component
export default class ScoreSelector extends Mixins(VuetifyMixin){
	mdiCheck = mdiCheck;

	@Prop({ default: null}) score: string;
	@Prop({ type: Boolean, default: false }) disabled: boolean;
	@Prop({ type: Boolean, default: false }) centered: boolean;
	@Prop({ type: Boolean, default: true }) small: boolean;
	@Prop({ type: Boolean, default: false }) isRecruitingProfile: boolean;
	@Prop({ type: String }) size: number;
	@Prop({ default: 'auto' }) height: string;
	@Prop({ default: [] }) items: SelectorItem[];
	@Prop() value: any | any[];
	@Prop({ default: 'value' }) itemValue: string;
	@Prop({ default: 'text' }) itemText: string;
	@Prop({ default: 'baColorGreen1' }) activeColor: string;
	@Prop({ type: Boolean, default: false}) isDetailPage: boolean;
	get ActiveColor(): string{
		return this.getColor(this.activeColor);
	}
	@Prop({ default: 'baColorPrimaryText' }) textColor: string;
	get TextColor(): string{
		return this.getColor(this.textColor);
	}
	@Prop({ default: 'baColorGray4' }) borderColor: string;
	get BorderColor(): string{
		return this.getColor(this.borderColor);
	}

	get MultipleSelect(): boolean{
		return this.$attrs.multiple !== undefined;
	}
	get chipGroupStyle(): Record<string, string>{
		return {
			'height': `${this.height}px`,
			'max-width': '500px'
		};
	}
	getChipStyle(item: SelectorItem): Record<string, string>{
		return {
			'font-size': '12px',
			'font-weight': this.isActive(item) ? '700' : 'normal',
			'color': this.TextColor,
			'border-color': this.BorderColor,
			'border-width': '1px',
			'width': '25%',
			'justify-content': 'center'
		};
	}
	get Width(): string{
		switch (this.$vuetify.breakpoint.name) {
		case 'xs':
			return '55px'
		case 'sm':
			return '89px';
		case 'md':
			return '103px';
		case 'lg': 
			return '119px';
		case 'xl':
			return '119px';
		default:
			return '119px';
		}
	}
	getChipClasses(index: number, item: SelectorItem): Record<string, boolean>{
		const active = this.isActive(item);
		return {
			'white--text': active,
			'rounded-l-lg': index === 0,
			'rounded-r-lg': index === (this.items.length - 1),
		};
	}

	onInput(data: SelectorItem): void{
		if (this.disabled === false){
			this.$emit('input', !this.isActive(data) ? data[this.itemValue] : null);
		}

	}
	isActive (item: SelectorItem): boolean {
		if (this.score != null) {
			return this.score === item.value;
		}
		else{
			return this.value === item.value;
		}
	}
	getChipColor (item: SelectorItem): string | undefined {
		if(this.isActive(item)){
			return this.ActiveColor;
		}
		return;
	}
}
