import { AccessControlledModel } from '../AccessControlledModel';
import { ScoutingReportPlayerDetails, ScoutingReportGameDetails, ScoutingReportEvaluationMatrix, ContactInfo, ScoutingReportEvaluationMatrixNotes } from '@best-athletes/ba-types';

export class ScoutingReportModel extends AccessControlledModel{
	version: number = 2;
	createdAt: Date = new Date();
	contactInfo: ContactInfo | null = null;
	lastModified: Date | null = null;
	anonymousCoach: boolean = false;

	playerDetails: ScoutingReportPlayerDetails;
	gameDetails: ScoutingReportGameDetails;
	evaluationMatrix: ScoutingReportEvaluationMatrix;
	evaluationMatrixNotes: ScoutingReportEvaluationMatrixNotes;

	submitted: boolean = false;

	/** True if all steps of the Evaluation Matrix have been given a grade */
	get IsComplete(): boolean{
		return this.submitted;
	}

	get PlayerName(): string | null{
		if(this.playerDetails.firstName && this.playerDetails.lastName){
			return `${this.playerDetails.firstName} ${this.playerDetails.lastName}`;
		}
		return null;
	}

	get HasContactInfo(): boolean{
		if(this.contactInfo === null) return false;
		return this.contactInfo.email !== "";
	}

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if(obj['createdAt']) this.createdAt = new Date(obj['createdAt']);
		if (obj['lastModified'] !== null) this.lastModified = new Date(obj['lastModified']);
		if (obj['playerDetails'] && obj['playerDetails']['dateOfBirth']){
			this.playerDetails.dateOfBirth = new Date(obj['playerDetails']['dateOfBirth']);
		}
		if (obj['gameDetails'] && obj['gameDetails']['evaluationDate']){
			this.gameDetails.evaluationDate = new Date(obj['gameDetails']['evaluationDate']);
		}
		if (obj['evaluationMatrixNotes'] === undefined) {
			this.evaluationMatrixNotes = {
				technical: null,
				tactical: null,
				physical: null,
				mental: null,
				attackingOrganization: null,
				attackingTransition: null,
				attackingSetPlays: null,
				defendingOrganization: null,
				defendingTransition: null,
				defendingSetPlays: null,
				competitive: null,
				resilience: null,
				intelligence: null,
				speed: null,
				presence: null,
				performanceScore: null,
				potentialScore: null,
			}
		}
		if (obj['playerDetails']['competitiveLevel'] == undefined) {
			this.playerDetails.competitiveLevel = null;
		}
		return this;
	}

}