
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiPlus, mdiChevronLeft, mdiChevronRight, mdiFormatListBulleted, mdiArrowExpandAll, mdiMagnify, mdiCalendarMonth, mdiCheck, mdiGoogle, mdiMicrosoftWindows, mdiUpload, mdiDownload } from '@mdi/js';
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { getDefaultEventTypes } from '../../helpers/default-event-types';
import { getMonthName, formatDate, formatDateHyphensYYYYMMDD } from '../../helpers/date';
import EventTypesGroup from './EventTypesGroup.vue';
import EventsAgenda from './EventsAgenda.vue';
import { VuetifyMixin, AuthMixin, CurrentTeamMixin, FeatureFlagMixin } from '../../mixins';
import { TeamEventTypeData } from '../../../types/interfaces';
import { CalendarMode, CalendarModeValues } from './types/CalendarMode';
import { VCalendarDay, VCalendarDayEventParsed } from './types/VCalendarDay';
import { title } from '../../pipes/title.pipe';
import BATextField from '@/components/inputs/BATextField.vue';
import CopyToClipboard from '@/components/ui/CopyToClipboard.vue';
import Dialog from "@/components/ui/Dialog.vue";
import FormGroupHeading from "@/components/ui/FormGroupHeading.vue";
import { CalendarUrls } from '../hoc/CalendarProvider.vue';
import SectionHeading from "@/components/ui/SectionHeading.vue";
import CalendarProvider from "@/components/hoc/CalendarProvider.vue";
import CalendarExportDialog from "@/components/calendar/CalendarExportDialog.vue";
import { DateTime } from "luxon";
import { BehaviorSubject } from 'rxjs';


@Component({
	components: { 
		EventsAgenda,
		EventTypesGroup,
		Dialog,
		FormGroupHeading,
		BATextField,
		CopyToClipboard,
		SectionHeading,
		CalendarProvider,
		CalendarExportDialog
	}
})
export default class CalendarV2 extends Mixins(VuetifyMixin, AuthMixin, CurrentTeamMixin, FeatureFlagMixin){
	mdiPlus = mdiPlus;
	mdiChevronLeft = mdiChevronLeft;
	mdiChevronRight = mdiChevronRight;
	mdiFormatListBulleted = mdiFormatListBulleted;
	mdiArrowExpandAll = mdiArrowExpandAll;
	mdiMagnify = mdiMagnify;
	mdiUpload = mdiUpload;
	mdiDownload = mdiDownload;
	mdiCalendarMonth = mdiCalendarMonth;
	mdiCheck = mdiCheck;
	mdiGoogle = mdiGoogle;
	mdiMicrosoftWindows = mdiMicrosoftWindows;
	title = title;
	$refs: {
		calendar: any,
		calendarProvider: CalendarProvider
	};

	monthViewEventList$: BehaviorSubject<CalendarEventModel[]> = new BehaviorSubject<CalendarEventModel[]>([]);

	created(): void {
		this.focusDate = formatDate(new Date())
		this.monthViewEventList$.subscribe(list => this.events = list)
	}

	get AthleteMode(): boolean{
		if(this.CurrentProfileType === 'athlete'){
			return true;
		}
		return false;
	}

	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop({ type: Boolean, default: false }) readOnly: boolean;

	events: CalendarEventModel[] = []

	@Prop({ type: String, default: CalendarMode.Agenda }) view!: string;

	focusDate: string = '';
	get FocusDate(): string {
		return this.focusDate;
	}
	set FocusDate(value: string) {
		// TODO: Update Focus date in provider
		this.focusDate = value
	}
	updateFocusDate(focusDate: string): void{
		this.focusDate = focusDate;
	}

	@Prop({ type: Number, default: 480 }) height: number;
	@Prop({ type: Array, default: getDefaultEventTypes() }) eventTypes!: TeamEventTypeData[];
	@Prop({ type: String, default: 'baColorVibrantBlueText' }) defaultEventColor: string;
	@Prop({ type: String, default: 'baColorVibrantBlueText' }) accentColor: string;
	@Prop({ type: String, default: 'baColorAppForeground' }) color: string;
	@Prop({ type: Boolean, default: false }) largeCalendar: boolean;

	showAddEvent = false;
	focusEvent = {}
	focusDay = null;
	eventFilters = this.eventTypes.filter(event => event.disabled !== true).map(event => event.name);
	types = CalendarModeValues;

	addEvent(focusDate?: string): void{
		if(this.readOnly) return;
		this.$emit('click:add-event', { focusDate });
	}

	weekdayFmt(date: string): string{
		const weekdays = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
		return `${weekdays[new Date(date).getUTCDay()]}`;
	}

	get ShowDatePicker(): boolean{
		return ["day", "week", "4day"].includes(this.view) && this.$vuetify.breakpoint.lgAndUp;
	}

	eventClicked(calendarEvent: CalendarEventModel, $event: VCalendarDay<CalendarEventModel>): void{
		this.$emit('click:event', { calendarEvent, $event });
	}

	toggleEventView(): void{
		if(this.view !== CalendarMode.Agenda){
			
			this.updateEventView(CalendarMode.Agenda);
		}else{
			this.updateEventView(CalendarMode.Month);
		}
	}
	updateEventView(view: string): void{
		this.view = view;
	}

	get CalendarTimeTitle(): string{
		if (this.$refs.calendar && this.view !== CalendarMode.Agenda){
			return this.$refs.calendar.title ? this.$refs.calendar.title : this.CurrentMonthName + ' ' + this.CurrentYear
		} else {
			return this.CurrentMonthName + ' ' + this.CurrentYear
		}
	}

	get IsDesktop(): boolean{
		return this.$vuetify.breakpoint.mdAndUp;
	}

	get IsTablet(): boolean{
		return this.$vuetify.breakpoint.sm;
	}

	get IsMobile(): boolean{
		return this.$vuetify.breakpoint.xs;
	}

	get ShowCalendarView(): boolean{
		return !this.ShowAgendaView;
	}
	get ShowAgendaView(): boolean{
		return this.view === CalendarMode.Agenda;
	}

	get CurrentDate(): Date{
		return new Date(this.focusDate);
	}
	get CurrentMonthName(): string{
		return getMonthName(this.CurrentDate);
	}
	get CurrentYear(): number{
		return this.CurrentDate.getUTCFullYear(); 
	}
	/**
	 * Returns only events who's type match the current filters. Events without a type are always displayed
	 */
	get FilteredEvents(): CalendarEventModel[]{
		return this.events.filter(
			event => event.eventType ? (this.eventFilters.find(
				type => type === event.eventType
			) !== undefined) : true
		);
	}
	get HeaderSheetStyle(): Record<string,any>{
		return {
			'border-bottom': `2px solid ${this.getColor('baColorGray12')} !important`,
		};
	}
	get Color(): string{
		return this.getColor(this.color);
	}
	async mounted(): Promise<void>{
		this.resetDate();
	}

	loadingCalendar = false;
	load() {
		this.loadingCalendar = true;
		this.$refs.calendarProvider.loadCalendar();
		this.loadingCalendar = false;
	}

	get IsLoading(): boolean{
		return this.loading || this.loadingCalendar;
	}

	async removeCalendarEvent(calendarEvent: CalendarEventModel): Promise<void>{
		this.$emit('remove:event', { calendarEvent });
	}

	/** v-calendar events */
	getEventColor (event: CalendarEventModel): string {
		let color = this.defaultEventColor;
		if(event.eventType){
			let type = this.eventTypes.find(type => type.name === event.eventType);
			if(type && type.color) color = type.color;
		}
		return color;
	}
	onUpdateRange(): void{
		this.$emit('update:range');
	}
	showEvent($event: VCalendarDay<CalendarEventModel>): void{
		const event: CalendarEventModel = $event.event;
		this.focusEvent = $event;
		this.eventClicked(event, $event);
	}
	clickDay($event: VCalendarDay<CalendarEventModel>): void{
		this.focusDay = new Date($event.date);
		this.FocusDate =  formatDate(new Date($event.date));		
		this.addEvent($event.date);
	}
	clickMore($event: VCalendarDay<CalendarEventModel>): void{
		this.FocusDate =  formatDate(new Date($event.date));
		this.focusDay = new Date($event.date);
	}
	resetDate(override?: Date): void{
		if(override === undefined){
			this.FocusDate =  formatDate(new Date());
		}else{
			this.FocusDate =  formatDate(override);
		}
	}
	prev (): void {
		if (this.view === CalendarMode.Month || this.ShowAgendaView){
			const currentDate = new Date(this.focusDate)
			const newDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
			this.FocusDate =  formatDate(newDate);
		} else if (this.view === CalendarMode.Week){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()-7
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate =  formatDate(newDate);
		} else if (this.view === CalendarMode.Day){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()-1
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate =  formatDate(newDate);
		} else if (this.view === CalendarMode.FourDay){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()-4
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate =  formatDate(newDate);
		}
	}
	next (): void {
		if (this.view === CalendarMode.Month || this.ShowAgendaView){
			const currentDate = new Date(this.focusDate)
			const newDate = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth()+1, 1)
			this.FocusDate =  formatDate(newDate);
		} else if (this.view === CalendarMode.Week){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()+7
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate =  formatDate(newDate);
		} else if (this.view === CalendarMode.Day){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()+1
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate =  formatDate(newDate);
		} else if (this.view === CalendarMode.FourDay){
			const currentDate = new Date(this.focusDate)
			const newDay = currentDate.getUTCDate()+4
			const newDate = new Date(currentDate.setUTCDate(newDay))
			this.FocusDate =  formatDate(newDate);
		}
	}

	exportDialogVisible = false;
	copyClicked = false;
	@Prop({ default: null }) calendarUrls: CalendarUrls;
	async toggleExportDialogVisiblility(): Promise<void> {
		this.exportDialogVisible  = !this.exportDialogVisible;
	}
	get CalendarUrls(): CalendarUrls | null {
		return this.calendarUrls !== undefined && this.calendarUrls !== null ? this.calendarUrls : null;
	}

	/**
	 * Returns a time output for the calendar
	 * @param eventParsed Parsed Event passed from v-calendar slot
	 */
	calendarTimeOutput(eventParsed: VCalendarDayEventParsed): string {
		const start = eventParsed.start;
		let format = "h"
		format += start.minute === 0 ? '' : ':mm'
		format += ' a'
		return DateTime.fromString(start.time, 'T').toFormat(format);
	}
}
