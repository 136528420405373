
import { Component, Mixins, Prop } from "vue-property-decorator";
import { title, role } from "../../pipes";
import CircledAvatar from "../ui/CircledAvatar.vue";
import { VuetifyMixin } from "../../mixins";
import { TeamDetailsMixin } from "../../mixins/TeamDetailsMixin";
import { mdiChevronDown } from '@mdi/js';

@Component({
	components: { CircledAvatar},
	filters: { title, role }
})
export default class TeamDetailsCardV2 extends Mixins(
	TeamDetailsMixin,
	VuetifyMixin
) {
	mdiChevronDown = mdiChevronDown;
	@Prop({ default: false, type: Boolean }) loading: boolean; 
	@Prop({ default: false, type: Boolean }) useChevron: boolean; 
}
